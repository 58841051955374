<template>
  <div :class="{root: true}" class="container-fluid">
    <div class="row justify-content-center align-content-center bg-dark" >      
      <div 
        class="col-12 col-lg-6 order-lg-1 bg-white padding-bottom" 
      >
        <div class="d-flex flex-column" :class="{'height-landscape':landscape, 'height-portrait': !landscape}">
          <div class="d-flex flex-grow-1 justify-content-center align-items-center">
            <router-view/>
          </div>
        </div>
      </div>
      <div 
        class="col-12 col-lg-6 order-lg-0 bg-dark padding-bottom" 
      >
        <div class="d-flex flex-column" :class="{'height-landscape':landscape, 'height-portrait': !landscape}">
          <div class="d-flex flex-grow-1 justify-content-center align-items-center">
            <AppData />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import AppData from '@/components/AppData.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Layout',
  props: {
    msg: String
  },
  components: {
    AppData
    , Footer
  },
  data() {
    return {
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  computed: {
    showNavBar : function() {return this.$store.getters.show_nav_bar},
    landscape : function() { return this.$store.getters.landscape },
  },
  created () {
    this.emitter.on('scrollToTop', this.scrollToTop);
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
  @import '~@/styles/app';
  .root {
    
  }

.content {

}
  
</style>
