<template>
  <AppModules />
  <router-view/>
</template>

<script>
import AppModules from '@/components/AppModules.vue'

export default {
  components: {
    AppModules
  },
  methods: {
    loadEvent(key_id) {
      let url = '/catalog/events/public'
      let method = 'post'
      this.$store.dispatch(method, {'url':url, data: {event_key_id: key_id}, headers:{}})
        .then((resp) => {
          this.$store.commit('set_event', resp.data)
          this.$store.commit('set_event_key_id', key_id)
          this.$nextTick(() => {
            this.listenChannel()
          })
        }).catch(err => {
          if(err && err.error)
            this.emitter.emit(
              'showMsgBox',
              {
                header: "Error",
                message: err.error,
                lblBtnClose: "Ok",
              }
            ) 
        })
    },
    listenChannel() {

      let channel = this.$pusher.subscribe('events-'+this.$store.getters.event_key_id);

      channel.bind('EventUpdated', data => {
        this.$store.commit('set_event', data)
      })

    },
    sizeUpdate() {
      this.$store.commit('set_landscape', window.innerWidth > window.innerHeight)
      if(window.innerHeight < 576) this.$store.commit('set_device_size', 'xs')
      if(window.innerHeight >= 576 && window.innerHeight < 768) this.$store.commit('set_device_size', 'sm')
      if(window.innerHeight >= 768 && window.innerHeight < 992) this.$store.commit('set_device_size', 'md')
      if(window.innerHeight >= 992 && window.innerHeight < 1200) this.$store.commit('set_device_size', 'lg')
      if(window.innerHeight >= 1200) this.$store.commit('set_device_size', 'xl')
    }
  },
  beforeMount () {
  },
  mounted () {
    window.addEventListener("online", (event) => {
      this.emitter.emit('fastAccess');
    });

    window.onresize = () => {
      this.sizeUpdate()
    }
    this.sizeUpdate()
  },
  computed: {
  },
  created () {
    this.emitter.on('loadEvent', this.loadEvent);
  },
}

</script>

<style lang="scss">
  @import '~@/styles/app';
</style>
